import { find as _find } from 'lodash';
import { orderBy, map, filter } from 'lodash';

export const mapVehicleAttributes = (cms, api) =>
  api.map((section) => {
    const matchingCmsData = cms.find((item) => item.id === section.id);

    return {
      id: section.id,
      title: matchingCmsData.title,
      items: section.items?.map((sectionItem) => {
        const matchingSectionItem = matchingCmsData.items.find(
          (item) => item.id === sectionItem.id,
        );

        return {
          ...sectionItem,
          title: matchingSectionItem?.title,
          target: matchingSectionItem?.target,
        };
      }),
    };
  });

// Vehicle damage data mapper
export const damageDataMapper = (damages) => {
  return damages.reduce(
    (accumulator, damage) =>
      accumulator.concat(
        (damage.damageLines || []).map((damageLine) => ({
          damageClassification: damage.damageClassification,
          damageSurveyDate: damage.damageSurveyDateFmt,
          damageSurveyTypeCode: damage.damageSurveyTypeCode,
          damageSurveyTypeDescription: damage.damageSurveyTypeDescription,
          damageComments: damageLine.damageComment,
          damageTypeCode: damageLine.damageTypeCode,
          damageTypeDescription: damageLine.damageTypeDescription,
          damageAreaCode: damageLine.damageAreaCode,
          damageAreaDescription: damageLine.damageAreaDescription,
          damageSeverityCode: damageLine.damageSeverityCode,
          damageSeverityDescription: damageLine.damageSeverityDescription,
          damageMetalAmount: damageLine.metalAmount,
          damagePaintAmount: damageLine.paintAmount,
          damagePartsAmount: damageLine.partsAmount,
          totalDamageAmount: damageLine.totalLineItemDamageAmount,
          repairDate: damageLine.damageLineRepairDateFmt,
          disclosableIndicator: damageLine.damageDisclosableIndicator,
          paintlessRepairIndicator: damageLine.paintlessRepairIndicator,
        })),
      ),
    [],
  );
};

// Customer requests series aggregate mapper
export const seriesAggregateData = (seriesData) => {
  const initialLoadSortedData = orderBy(
    seriesData?.map((series) => ({
      ...series,
      seriesName: series.salesSeriesName,
      soldOrder: series.soldOrderCount,
      preSold: series.preSoldCount,
      reserve: series.reserveCount,
    })),
    ['total', 'brandSort', 'carTruckCode', 'seriesName'],
    ['desc', 'desc', 'asc', 'asc'],
  ).slice(0, 20);

  const initialLoadSortedSeries = map(initialLoadSortedData, 'seriesName');
  const remainingData = filter(
    seriesData,
    (item) => !initialLoadSortedSeries?.includes(item.salesSeriesName),
  );
  const remainingSortedData = orderBy(
    remainingData?.map((series) => ({
      ...series,
      seriesName: series.salesSeriesName,
      soldOrder: series.soldOrderCount,
      preSold: series.preSoldCount,
      reserve: series.reserveCount,
    })),
    ['brandSort', 'carTruckCode', 'seriesName'],
    ['desc', 'asc', 'asc'],
  );

  return [...initialLoadSortedData, ...remainingSortedData];
};

// lifecycle date mapper for vehicle record panel & full page
export const mapLifecycleDate = (vehicle) => {
  switch (vehicle?.main?.header?.lifeCycleStatus) {
    case 'Unbuilt': {
      if (vehicle?.manufacturing?.plantInformation?.napCbuCode === 'Import') {
        return {
          label: 'Est. Build Date',
          value: vehicle?.manufacturing?.keyProductionDates?.estimatedBuildDateFmt || 'N/A',
        };
      }
      if (vehicle?.manufacturing?.plantInformation?.napCbuCode === 'Domestic') {
        if (vehicle?.manufacturing?.keyProductionDates?.estimatedAssemblyDateFmt) {
          return {
            label: 'Est. Assembly Start',
            value: vehicle?.manufacturing?.keyProductionDates?.estimatedAssemblyDateFmt,
          };
        }
        if (vehicle?.manufacturing?.keyProductionDates?.estimatedPaintDateFmt) {
          return {
            label: 'Est. Paint Start',
            value: vehicle?.manufacturing?.keyProductionDates?.estimatedPaintDateFmt,
          };
        }
        if (vehicle?.manufacturing?.keyProductionDates?.estimatedBodyEndDateFmt) {
          return {
            label: 'Est. Body',
            value: vehicle?.manufacturing?.keyProductionDates?.estimatedBodyEndDateFmt,
          };
        }
      }

      return null;
    }
    case 'Pre Process in Transit to VPC':
      return {
        label: 'ETA',
        value: `${vehicle?.vehicleTracking?.swCurrWindowStartFmt || 'N/A'} - ${
          vehicle?.vehicleTracking?.swCurrWindowEndFmt || 'N/A'
        }`,
      };
    case 'VPC Stock': {
      if (!vehicle?.logistics?.actualFQADateFmt) {
        return {
          label: 'Est. Processing Date',
          value: vehicle?.logistics?.estimatedFQADateFmt || 'N/A',
        };
      }
      return {
        label: 'ETA',
        value: `${vehicle?.vehicleTracking?.swCurrWindowStartFmt || 'N/A'} - ${
          vehicle?.vehicleTracking?.swCurrWindowEndFmt || 'N/A'
        }`,
      };
    }
    case 'In Transit to Other VPC':
      return {
        label: 'ETA',
        value: `${vehicle?.vehicleTracking?.swCurrWindowStartFmt || 'N/A'} - ${
          vehicle?.vehicleTracking?.swCurrWindowEndFmt || 'N/A'
        }`,
      };
    case 'Other VPC Stock':
      return {
        label: 'Act. Rail Arrival Date',
        value: `${vehicle?.vehicleTracking?.railTransport?.actualArrivalDateFmt || 'N/A'}`,
      };
    case 'Post Process in Transit':
      return {
        label: 'ETA',
        value: `${vehicle?.vehicleTracking?.swCurrWindowStartFmt || 'N/A'} - ${
          vehicle?.vehicleTracking?.swCurrWindowEndFmt || 'N/A'
        }`,
      };
    case 'Dealer Stock':
    case 'Company Stock':
      return (
        (vehicle?.generalInfo?.vehicleSummary?.rdrDateFmt ||
          vehicle?.logistics?.deliveryDateFmt) && {
          label: 'Delivered Date',
          value:
            vehicle?.generalInfo?.vehicleSummary?.rdrDateFmt || vehicle?.logistics?.deliveryDateFmt,
        }
      );
    case 'Retail':
      return {
        label: 'RDR Date',
        value: vehicle?.generalInfo?.vehicleSummary?.rdrDateFmt || 'N/A',
      };
    default:
      return null;
  }
};

export const getViewAndTabValueByTableId = ({ config, tableId }) => {
  let viewConfig;
  let tabValue;
  let radioButtons;
  let isShowPerformance;
  let isShowPipelineDistributor;
  config.forEach((viewCategory) => {
    viewCategory.views.forEach((view) => {
      const tabValueFound = view.tabs.find(
        (tab) => tab.value === tableId || !!_find(tab.radioButtons, { tableId }),
      );
      if (tabValueFound) {
        viewConfig = view;
        tabValue = tabValueFound.value;
        isShowPerformance = tabValueFound?.isShowPerformance || false;
        isShowPipelineDistributor = tabValueFound?.isShowPipelineDistributor || false;
        radioButtons =
          tabValueFound?.radioButtons?.map((item) => ({ ...item, id: item.tableId })) || [];
      }
    });
  });
  return { viewConfig, tabValue, isShowPerformance, isShowPipelineDistributor, radioButtons };
};

const VIN_TABLE_POSTFIX = 'Vin';
export const getVinTableIdByTableId = ({ tableId }) => `${tableId}${VIN_TABLE_POSTFIX}`;

export const getIsVinTableId = ({ tableId }) => {
  if (tableId === 'byDeletionsVin' || tableId === 'byDeletionsOtherDistributorsVin') {
    return false;
  }
  const lastThreeChars = tableId.slice(-VIN_TABLE_POSTFIX.length);
  return VIN_TABLE_POSTFIX === lastThreeChars;
};
