import { filter, groupBy, isEmpty, keys, forOwn, get } from 'lodash';
import { operators } from 'utils';
import { cleanObject } from './general';

export const getContainsObj = ({ field, value }) => ({
  [field]: {
    contains: value,
  },
});

export const getAndEqObj = ({ fields, values }) => {
  if (fields.length !== values.length) {
    return;
  }

  return {
    and: fields.map((field, index) => {
      return { [field]: { eq: values[index] } };
    }),
  };
};

export const getOperator = ({ formattedFilter }) => {
  const formattedFilterKey = keys(formattedFilter)[0];
  if (formattedFilterKey === 'or') {
    return 'or';
  } else if (formattedFilterKey === 'and') {
    return 'and';
  }
  return null;
};

export const getEqObj = ({ field, value }) => ({
  [field]: {
    eq: value,
  },
});

export const getInObj = ({ field, value }) => ({
  [field]: {
    in: value,
  },
});

const getOrObj = ({ fields }) => {
  const orObj = [];
  fields.forEach((fieldObj) => {
    orObj.push(getEqObj({ field: fieldObj.field, value: fieldObj.value }));
  });
  return { or: orObj };
};

const isAndOperator = ({ formattedFilter }) => get(formattedFilter, 'and');

const getFilterArray = ({ formattedFilter }) => {
  if (isEmpty(formattedFilter)) {
    return [];
  }
  if (isAndOperator({ formattedFilter })) {
    return formattedFilter?.and || [];
  }
  return [formattedFilter];
};

export const mergeFormattedFilters = ({ first, second, third }) => {
  return cleanObject({
    and: [
      ...getFilterArray({ formattedFilter: first }),
      ...getFilterArray({ formattedFilter: second }),
      ...getFilterArray({ formattedFilter: third }),
    ],
  });
};

export const cascadingFiltersFormatter = ({ cascadingFilters }) => {
  if (isEmpty(cascadingFilters) || !!cascadingFilters.find((f) => f.isCascadingFilterOff)) {
    return {};
  }
  let filtersFormatted = [];
  const filteredIsCheckedTrue = filter(cascadingFilters, { isChecked: true });
  const filtersGrouped = groupBy(filteredIsCheckedTrue, 'field');
  forOwn(filtersGrouped, (value, field) => {
    if (value.length > 1) {
      filtersFormatted = [...filtersFormatted, getOrObj({ fields: value })];
    } else {
      filtersFormatted.push(getEqObj({ field, value: value[0].value }));
    }
  });
  return cleanObject({ and: filtersFormatted });
};

export const getNumericInlineValue = (condition) => {
  const [conditionsArray] = Object.entries(condition);
  const operatorName = conditionsArray[0];
  const value = conditionsArray[1];
  let inlineValue = '';

  if (operatorName !== 'eq') {
    operators
      .filter((operator) => operator.type === 'number')
      .forEach((operator) => {
        if (operator.value === operatorName) {
          inlineValue += operator.label;
        }
      });
  }
  inlineValue += value;

  return inlineValue;
};
